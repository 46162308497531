import axios        from '../../Axios-Simpool';
import axiosRooc        from 'axios';
import {
    getTokenService,getTokenTransactionHistory,getTokenTransactionHistoryTopUp,
    getTemplateToken,createToken,getTemplateServiceToken,baseExternalServiceChatBotUrl, getTokenParamUrl,
    getDataTopUp
} from '../../shared/apiUrl';
import { toLogout,msgErrorHandler } from '../../shared/maskFunc';
import { getErrorJsonData } from '../../shared/utility';

export function* initGetTokenServiceSaga(action) {
    try {
        const response = yield axios.get(getTokenService).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);//yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* initGetTokenTrasactionHistorySaga(action) {
    try {
        const response = yield axios.post(getTokenTransactionHistory,action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* initGetTokenTrasactionHistoryTopUpSaga(action) {
    try {
        const response = yield axios.post(getTokenTransactionHistoryTopUp,action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* initGetTemplateTokenSaga(action) {
    try {
        const response = yield axios.get(getTemplateToken).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* initCreateTokenSaga(action) {
    try {
        const response = yield axios.post(createToken,action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        // action.errorCallback(errMessages);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}

export function* initGetTemplateServiceTokenSaga(action) {
    try {
        const response = yield axios.get(getTemplateServiceToken).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* initInsertServiceTokenSaga(action) {
    try {
        const response = yield axios.post(getTokenService,action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* initGetServiceSaga(action) {
    try {
        const response = yield axios.get(baseExternalServiceChatBotUrl).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* initGetTokenRoocSaga(action) {
    try {
        let urlRooc = sessionStorage.getItem("URL-ROOC")+'/auth/login';
        const response = yield axiosRooc.post(urlRooc,action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        // toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(error);
    }
}

export function* initGetTokenTrasactionHistoryParamSaga(action) {
    try {
        const response = yield axios.post(getTokenTransactionHistory,action.payload).then(response => response.data);
        action.successCallback(response,action.actiontype);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* initGetTokenTrasactionHistoryTopUpParamSaga(action) {
    try {
        const response = yield axios.post(getTokenTransactionHistoryTopUp,action.payload).then(response => response.data);
        action.successCallback(response,action.actiontype);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getTokenDataSaga(action) {
    try {
        const response = yield axios.get(getTokenParamUrl(action.param)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}

export function* submitTokenSaga(action) {
    try {
        if(action.actiontype == "POST"){
            const response = yield axios.post(getTokenParamUrl(action.param),action.payload).then(response => response.data);
            action.successCallback(response);
        }
    } catch (error) {
        toLogout(error);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}

export function* getTopUpDataSaga(action) {
    let urlParam = action.param?.url?action.param.url:'';
    try {
        const response = yield axios.get(getDataTopUp(urlParam)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}