import axios        from '../../Axios-Simpool';
import axiosImage   from '../../Axios-Image';
// import {put}        from 'redux-saga/effects';
// import * as actions from '../actions';
import {
    getListStaff,getListLoanProduct,loansUrl,listLoansUrl,listLoansSearchQueryUrl,
    submitLoanApproveOrRejectURL,listprovisioningcategory,loansTemplate,loansv3,scheduleLoans,
    paymentToLoanURL,getLoanTransTemplateByIdAndCommandURL,submitTransByIdAndCommandURL,loansChargeTemplateURL,
    loansChargeURL,loansTemplateURL,getAccTransferTemplateURL,basedAccountTransfersURL,loansByParamURL,getFindLoanAccNumberUrl,
    loanPassBookCoverUrl,printloanpassbookUrl,loanpassbookresetUrl,loansWithParamURL, loansNotesUrl, loansDocumentsUrl, loansDocAttachmentUrl, loansDocumentsIdUrl,loansSubmissionPagingParamURL,loanProductUrl, provisioningcriteriaUrl
}                   from '../../shared/apiUrl';
import { toLogout,msgErrorHandler } from '../../shared/maskFunc';
import { getErrorJsonData } from '../../shared/utility';

export function* getListStaffSaga(action) {
    try {
        const response = yield axios.get(getListStaff, {
            params: {
                // loanOfficersOnly: true,
                //s.is_loan_officer is 'true'
                sqlSearch:"s.is_loan_officer = 'true' ",
                officeId:action.officeid
            },
        }).then(response => response.data)
        // console.log("masuk api RM 2, ",response);
            // .catch(error => console.log(error.response.data));
            action.successHandler(response);
    }catch (error) {
        action.errorHandler(error.data.message);
    }
}

export function* getListLoanProductSaga(action) {
    try {
        const response = yield axios.get(getListLoanProduct, {
        }).then(response => response.data)
        // console.log("masuk api RM 2, ",response);
            // .catch(error => console.log(error.response.data));
            action.successHandler(response);
    }catch (error) {
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getListLoanDetailSaga(action) {
    try {
        const response = yield axios.get(loansUrl(action.loanid), {
        }).then(response => response.data)
        // console.log("masuk api RM 2, ",response);
            // .catch(error => console.log(error.response.data));
            action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* initGetListLoanSaga(action) {
    try {
        const response = yield axios.get(listLoansUrl).then(response => response.data);
        action.successCallback(response, 'api');
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* initGetListLoanSearchSaga(action) {
    try {
        const response = yield axios.get(listLoansSearchQueryUrl(action.query)).then(response => response.data);
        action.successCallback(response, 'api');
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* submitApproveOrRejectSaga(action) {
    try {
        const response = yield axios.post(submitLoanApproveOrRejectURL(action.id,action.command),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* initGetListProvisioningCategorySaga(action) {
    try {
        const response = yield axios.get(listprovisioningcategory).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getLoansTemplateSaga(action) {
    try {
        const response = yield axios.get(loansTemplate(action.clientid,action.productloanid), {
        }).then(response => response.data)
        // console.log("masuk api RM 2, ",response);
            // .catch(error => console.log(error.response.data));
            action.successCallback(response);
    }catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* createLoansSaga(action) {
    try {
        const response = yield axios.post(loansv3,action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        // toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        // const errMessages = msgErrorHandler(error);
        // action.errorCallback(errMessages);
        action.errorCallback(getErrorJsonData({errors:error}));

    }
}

export function* scheduleLoansSaga(action) {
    try {
        const response = yield axios.post(scheduleLoans,action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* paymentToLoansSaga(action) {
    try {
        const response = yield axios.post(paymentToLoanURL,action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getLoanTransTemplateByIdAndCommandSaga(action) {
    try {
        const response = yield axios.get(getLoanTransTemplateByIdAndCommandURL(action.id,action.command)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* submitLoanTransByIdAndCommandSaga(action) {
    let command = action.command;
    try {
        const response = yield axios.post(submitTransByIdAndCommandURL(action.id,command),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        if(command == 'loanrepaymentteller'){
            action.errorCallback(getErrorJsonData({errors:error}));
        }else{
            const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
            action.errorCallback(errMessages);
        }
    }
}

export function* submitDisbursementMultipleSaga(action) {
    try {
        const response = yield axios.post(submitLoanApproveOrRejectURL(action.id,action.command),action.payload).then(response => response.data);
        action.successCallback(response,action.loansid);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages,action.loansid);
    }
}

export function* getAccTransferTemplateSaga(action) {
    try {
        const response = yield axios.get(getAccTransferTemplateURL(action.param)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getLoanChargeTemplateSaga(action) {
    try {
        const response = yield axios.get(loansChargeTemplateURL(action.id)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* submitTransferOverpaidLoanSaga(action) {
    try {
        const response = yield axios.post(basedAccountTransfersURL,action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* submitLoanChargesSaga(action) {
    try {
        const response = yield axios.post(loansChargeURL(action.id),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getLoanTemplateByQueryParamSaga(action) {
    try {
        const response = yield axios.get(loansTemplateURL(action.queryparam)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* editChargeInLoanSaga(action) {
    try {
        const response = yield axios.put(loansChargeURL(action.loanid)+'/'+action.chargeid,action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* deleteChargeInLoanSaga(action) {
    try {
        const response = yield axios.delete(loansChargeURL(action.loanid)+'/'+action.chargeid).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getDetailChargeInLoanSaga(action) {
    try {
        const response = yield axios.get(loansChargeURL(action.loanid)+'/'+action.chargeid).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* submitWaiveLoanChargesSaga(action) {
    try {
        const response = yield axios.post(loansChargeURL(action.loanid)+'/'+action.chargeid+action.command,action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getLoanDataParamDataImportSaga(action) {
    try {
        const response = yield axios.get(loansWithParamURL(action.param)).then(response => response.data);
        action.successCallback(response,action.paramvalue);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}
export function* getLoansParamSaga(action) {
    try {
        const response = yield axios.get(loansByParamURL(action.param)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* submitLoanTransByIdAndCommandMultipleSaga(action) {
    try {
        const response = yield axios.post(submitTransByIdAndCommandURL(action.id,action.command),action.payload).then(response => response.data);
        action.successCallback(response,action.to,action.row,action.listdata,action.listerr,action.listdone,action.extractresult,action.dispatch);
    } catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);//yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages,action.to,action.row,action.listdata,action.listerr,action.listdone,action.extractresult,action.dispatch);
    }
}


export function* submitLoanTransByIdAndCommandDataImportSaga(action) {
    
    try {
        const response = yield axios.post(submitTransByIdAndCommandURL(action.id,action.command),action.payload).then(response => response.data);
        action.successCallback(response,action.extractresult);
    } catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);//yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages,action.extractresult);
    }
}
export function* submitEditLoansSaga(action) {
    try {
        const response = yield axios.put(loansByParamURL('/'+action.id),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getFindLoansAccNumberSaga(action) {
    try {
        const response = yield axios.get(getFindLoanAccNumberUrl(action.param)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* submitLoanPassBookCoverSaga(action) {
    try {
        const response = yield axios.post(loanPassBookCoverUrl(action.param),action.payload).then(response => response.data);
        action.successCallback(response,action.payload);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getLoanPrintPassbookSaga(action) {
    try {
        const response = yield axios.get(printloanpassbookUrl(action.param)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* submitLoanPassbookResetSaga(action) {
    try {
        const response = yield axios.post(loanpassbookresetUrl(action.param),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* submitLoanRePrintPassbookSaga(action) {
    try {
        const response = yield axios.post(printloanpassbookUrl(action.param),action.payload).then(response => response.data);
        action.successCallback(response,action.payload);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        const errMessages = msgErrorHandler(error);
        action.errorCallback(errMessages);
    }
}

export function* getLoanPagingSaga(action) {
    try {
        const response = yield axios.get(loansWithParamURL(action.queryparam)).then(response => response.data);
        action.successHandler(response,action.actiontype);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}
export function* getSubmissionLoanPagingSaga(action) {
    try {
        const response = yield axios.get(loansSubmissionPagingParamURL(action.queryparam)).then(response => response.data);
        action.successHandler(response,action.actiontype);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}
export function* getNotesLoanSaga(action) {
    try {
        const response = yield axios.get(loansNotesUrl(action.param)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* submitNotesLoanSaga(action) {
    try {
        const response = yield axios.post(loansNotesUrl(action.param),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        const errMessages = msgErrorHandler(error);
        action.errorCallback(errMessages);
    }
}

export function* getListDocumentLoanSaga(action) {
    try {
        const response = yield axios.get(loansDocumentsUrl(action.param)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getDownloadDocumentLoanSaga(action) {
    try {
        const response = yield axiosImage.get(loansDocAttachmentUrl(action.idloan,action.iddoc)).then(response => response.data);
        action.successCallback(response,action.iddoc);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* deleteDocumentLoanSaga(action) {
    try {
        const response = yield axios.delete(loansDocumentsIdUrl(action.idloan,action.iddoc)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* submitAddDocumentLoanSaga(action) {
    try {
        const response = yield axios.post(loansDocumentsUrl(action.idloan),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* getProductLoanSaga(action) {
    try {
        const response = yield axios.get(loanProductUrl(action.param)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages);
    }
}

export function* submitAddProductLoanSaga(action) {
    try {
        const response = yield axios.post(loanProductUrl(action.param),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        // const errMessages = msgErrorHandler(error);
        // action.errorCallback(errMessages);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}

export function* submitEditProductLoanSaga(action) {
    try {
        const response = yield axios.put(loanProductUrl(action.param),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        // toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        // const errMessages = msgErrorHandler(error);
        // action.errorCallback(errMessages);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}

export function* submitInactiveChargeLoanSaga(action) {
    try {
        const response = yield axios.post(loansWithParamURL(action.param),action.payload).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        const errMessages = msgErrorHandler(error);
        action.errorCallback(errMessages);
    }
}

export function* getLoansSaga(action) {
    try {
        let subUrl = action.param.url;
        const response = yield axios.get(loansWithParamURL(subUrl)).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        // toLogout(error);
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}

export function* submitLoansSaga(action) {
    let params = action.param.params?action.param.params:null;
    try {
        let subUrl = action.param.url;
        let payload = action.param.payload?action.param.payload:'';
        let type = action.param.type?action.param.type:'';
        if(type == 'POST'){
            const response = yield axios.post(loansWithParamURL(subUrl),payload).then(response => response.data);
            action.successHandler(response,params);
        }else if(type == 'PUT'){
            const response = yield axios.put(loansWithParamURL(subUrl),payload).then(response => response.data);
            action.successHandler(response,params);
        }
        
    } catch (error) {
            action.errorHandler(getErrorJsonData({errors:error}) , params);
    }
}
            
export function* getProvosioningCriteriaSaga(action) {
    try {
        const response = yield axios.get(provisioningcriteriaUrl(action.param)).then(response => response.data);
        action.successCallback(response);
    } catch (error) {
        toLogout(error);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}

export function* submitProvosioningCriteriaSaga(action) {
    try {
        if(action.actiontype == 'POST'){
            const response = yield axios.post(provisioningcriteriaUrl(action.param),action.payload).then(response => response.data);
            action.successCallback(response);
        }else if(action.actiontype == 'PUT'){
            const response = yield axios.put(provisioningcriteriaUrl(action.param),action.payload).then(response => response.data);
            action.successCallback(response);
        }else if(action.actiontype == 'DELETE'){
            const response = yield axios.delete(provisioningcriteriaUrl(action.param)).then(response => response.data);
            action.successCallback(response);
        }
        
    } catch (error) {
        toLogout(error);
        action.errorCallback(getErrorJsonData({errors:error}));
    }
}
